import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheese,
  faCookie,
  faHeart,
  faSmileBeam
} from '@fortawesome/free-solid-svg-icons'

import Loading from './Loading'

const PlaceHolder = () => (
  <div className='d-flex justify-content-center w-100'>
    {' '}
    {[faCheese, faSmileBeam, faHeart, faCookie].map((icon, i) => (
      <span className='mx-3' key={i}>
        <FontAwesomeIcon icon={icon} size='2x' className='text-xlight' />
      </span>
    ))}
  </div>
)

type Props = {
  loading: boolean
  nav: boolean
  className: string
}

const Actions: React.FC<Props> = ({ loading, children, className, nav }) => (
  <div className={`actions ${className ? className : ''}`}>
    <div
      className={`container-sm d-flex align-items-center item-container ${
        nav ? 'nav-spacing' : ''
      }`}
    >
      {loading ? <Loading /> : children ? children : <PlaceHolder />}
    </div>
  </div>
)

export { PlaceHolder }
export default Actions
