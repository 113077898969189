import * as React from 'react'
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AlertComponentProps } from 'react-alert'

const typeMap = {
  info: 'info',
  success: 'success',
  error: 'danger'
}

const icons = {
  info: <FontAwesomeIcon icon={faInfoCircle} />,
  success: <FontAwesomeIcon icon={faCheckCircle} />,
  error: <FontAwesomeIcon icon={faExclamationCircle} />
}

const AlertTemplate = ({ options, message, close }: AlertComponentProps) => (
  <div
    className={`alert alert-${
      options.type ? typeMap[options.type] : 'info'
    } alert-dismissible`}
    role='alert'
  >
    {options.type ? icons[options.type] : icons.info} {message}
    <button
      type='button'
      className='btn-close'
      data-bs-dismiss='alert'
      aria-label='Close'
      onClick={close}
    ></button>
  </div>
)

export default AlertTemplate
