import * as React from 'react'
import {
  faFacebookSquare,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import applePay from '../images/apple-pay.svg'
import bancontact from '../images/bancontact.svg'
import googlePay from '../images/google-pay.svg'
import ideal from '../images/ideal.svg'
import mastercard from '../images/mastercard.svg'
import visa from '../images/visa.svg'
// import Links from './Links'

const Footer: React.FC = props => (
  <footer className='my-4 px-5 container-sm' {...props}>
    <div className='row'>
      <div className='col-sm-12 col-md-4'>
        <h3>Contact</h3>
        <p>
          Heb je een vraag of wil je gewoon even gedag zeggen? Stuur een mailtje
          naar <a href='mailto:nina@goegebakken.be'>nina@goegebakken.be</a> of
          bel/sms naar <a href='tel:+32484617587'>0484 61 75 87</a>.
        </p>

        <p>
          GOEGEBAKKEN
          <br />
          Streepstraat 67
          <br />
          2950 Kapellen
          <br />
          BTW BE0687.910.439
        </p>
      </div>
      <div className='col-sm-12 col-md-4'>
        <h3>Volg ons</h3>
        <ul className='list-unstyled'>
          <li>
            <a
              href='https://www.instagram.com/goegebakken.be/'
              target='_blank'
              rel='noreferrer'
              className='d-inline-flex'
            >
              <FontAwesomeIcon size='2x' icon={faInstagram} className='me-2' />{' '}
              Instagram
            </a>
          </li>
          <li>
            <a
              href='https://www.facebook.com/goegebakken.be/'
              target='_blank'
              rel='noreferrer'
              className='d-inline-flex'
            >
              <FontAwesomeIcon
                size='2x'
                icon={faFacebookSquare}
                className='me-2'
              />{' '}
              Facebook
            </a>
          </li>
        </ul>
        <h3>Betaalmogelijkheden</h3>
        <ul className='list-unstyled list-inline'>
          {[
            { icon: bancontact, name: 'Bancontact' },
            { icon: visa, name: 'Visa' },
            { icon: mastercard, name: 'Mastercard' },
            { icon: applePay, name: 'Apple Pay' },
            { icon: googlePay, name: 'Google Pay' },
            { icon: ideal, name: 'IDeal' }
          ].map(({ icon, name }, i) => (
            <li className='list-inline-item' key={i}>
              <img src={icon} alt={name} width='36px' height='22px' />
            </li>
          ))}
        </ul>
      </div>
      <div className='col-sm-12 col-md-4'>
        <h3>Links</h3>
        {/* <Links /> */}
      </div>
    </div>
  </footer>
)

export default Footer
