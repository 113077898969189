import * as React from 'react'
import Error from './Error'
import Loading from './Loading'
import Menu from './Menu'
import Alert from './Alert'
import { positions, Provider as AlertProvider } from 'react-alert'
import Footer from './Footer'
import Actions from './Actions'
import VisibilitySensor from 'react-visibility-sensor'

type Props = {
  loading?: boolean
  error?: string
  actions?: React.ReactElement
  className?: string
  navActions?: boolean
}
const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  containerStyle: {
    padding: '5rem 1rem'
  }
}

const Layout: React.FC<Props> = ({
  loading = false,
  error = '',
  children,
  actions = <></>,
  className,
  navActions = false
}) => {
  const [actionFixed, setActionFixed] = React.useState(true)
  return (
    <AlertProvider template={Alert} {...alertOptions}>
      <div id='main'>
        <Menu />
        <VisibilitySensor
          onChange={visible => setActionFixed(!visible)}
          // @ts-ignore
          partialVisibility={'bottom'}
          offset={{ bottom: 120 }}
        >
          <article {...{ className }}>
            {loading ? (
              <Loading />
            ) : error ? (
              <Error message={error} />
            ) : (
              children
            )}
          </article>
        </VisibilitySensor>
        <Actions
          {...{ loading }}
          className={actionFixed ? 'fixed-bottom' : ''}
          nav={navActions}
        >
          {actions}
        </Actions>
        <Footer />
      </div>
    </AlertProvider>
  )
}

export default Layout
