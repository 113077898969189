import * as React from 'react'

const Error: React.FC<{ message: string }> = ({ message }) => (
  <div className='d-flex justify-content-center align-items-center'>
    <div className='alert alert-danger' role='alert'>
      <h1>Oepst! Er ging iets mis...</h1>
      <p>
        Foutmelding: <br />
        {JSON.stringify(message, null, 2)}
      </p>
    </div>
  </div>
)

export default Error
