import * as React from 'react'
// import { Collapse } from 'reactstrap'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

// import CartLink from './CartLink'
import logo_white from '../images/goegebakken_logo_wit.svg'
// import Links from './Links'

const Menu: React.FC = () => {
  const [collapsed, setCollapsed] = React.useState(true)

  const toggleNavbar = () => setCollapsed(!collapsed)

  return (
    <nav className='fixed-top navbar navbar-light'>
      <div className='nav-top-container d-flex justify-content-between align-items-center container-md'>
        <Link to='/' className='navbar-brand me-auto ml d-flex'>
          <img
            src={logo_white}
            className='img-fluid me-3'
            alt='Goegebakken logo'
            width='48px'
            height='48px'
          />
          <span className='align-self-center'>Goegebakken</span>
        </Link>
        {/* <CartLink /> */}
        <button
          className='btn btn-outline-primary btn-icon bg-white mx-2'
          onClick={toggleNavbar}
          aria-label='Open menu'
        >
          <FontAwesomeIcon size={'lg'} icon={faBars} />
        </button>
      </div>
      {/* <Collapse isOpen={!collapsed} navbar className='mx-2'>
        <div className='container-md d-flex flex-column menu-collapse nav-dropdown-container'>
          <Links
            className='navbar-nav'
            itemClassName='nav-item'
            linkClassName='nav-link'
          />
        </div>
      </Collapse> */}
    </nav>
  )
}

export default Menu
