import * as React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
  description: string
  lang?: string
  meta?: {
    name: string
    content: string
  }[]
  title: string
  siteTitle: string
  ldJson?: JSONObject
  author: string
}

const Seo = ({
  description,
  lang,
  meta,
  title,
  siteTitle,
  ldJson,
  author
}: Props) => {
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      /* eslint-disable-next-line */
      titleTemplate={`%s | ${siteTitle}`}
      meta={[
        {
          name: 'description',
          content: description
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: description
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: description
        }
      ].concat(meta || [])}
    >
      {ldJson && (
        <script type='application/ld+json'>{JSON.stringify(ldJson)}</script>
      )}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: 'nl',
  meta: [],
  description: ''
}

export default Seo
